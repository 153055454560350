import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import {API_BASE_URL} from 'redux/constants'
import {Image_BASE_URL} from 'redux/constants'


function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async ({ userName, password }) => {
        try {
		
			//  const resp = await apiSignIn({ userName, password })


			const response = await fetch(`${API_BASE_URL}Merchant/LogIn`, {
				method: "POST",
				body: JSON.stringify({
				  telephoneNumber: userName,
				  passcode: password
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
				  }
			  });

			  var resp = await response.json();
			if (resp.status) {
				 
				//  const {token}  = resp.data.token
				//  alert(token)
				// const id  = resp.data.id
				
		
				if(resp.data) {

					localStorage.setItem('items', JSON.stringify(resp.data));	
					localStorage.setItem('business', JSON.stringify(resp.data1));			
					dispatch(setUser({ 
						avatar: `${Image_BASE_URL}/${resp.data.imagePath}`,
						userName: resp.data.firstName, 
						businessName: resp.data1?.businessName, 
						merchantId: resp.data.id,
						email: resp.data.emailAddress
					}))
				}
				// const redirectUrl = query.get(REDIRECT_URL_KEY)
				if (resp.data1 != null) {
					dispatch(onSignInSuccess("token"))
					navigate("/dashboard")
				}
				else{
					navigate("/business-information");
				}		
				
                return {
                    status: 'success',
                    message: resp.message
                }
			}
			else{
				return {
					status: 'failed',
					message: resp.message				
				}
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
		localStorage.clear('items')
		window.location.reload()
	}

    const signOut = async () => {
		try {
			await apiSignOut()
			handleSignOut()
		} catch (errors) {
			handleSignOut()
		}
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
        signOut
    }
}

export default useAuth